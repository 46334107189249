import { ReactNode } from 'react';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';
import { PERMISSIONS, hasFunctionality } from '@bou-company/front-utils';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    window.location.href = hasFunctionality(PERMISSIONS.ORDER) ? PATH_DASHBOARD.root : "/";
    //return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
